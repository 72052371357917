export function handlePagination(to, getPaginator) {
    const requestedPage = Number(to.query.page || 0);
    if (!requestedPage) {
        return;
    }

    const paginator = getPaginator(to);
    if (!paginator) {
        return;
    }

    if (requestedPage <= paginator.current_page) {
        return;
    }

    return navigateTo(
        {
            path: to.path,
            query: { ...to.query, page: paginator.current_page },
        },
        { redirectCode: 301, replace: true }
    );
}
